<template>
  <div>
    <div class="header">
      <img @click="goHomePage" class="logo" src="@/assets/img/2a-07.png" alt="">
      <div class="right">
        <div class="tabs">
          <div v-for="(tab, index) in tabs.slice(0,tabs.length)" :key="index" class="tab"
            :class="{ 'active': currentIndex === index, 'hover': hoverIndex === index }" @click="selectTab(index)"
            @mouseover="hoverTab(index)" @mouseleave="leaveTab()">
            <button v-if="tab.name==='AI-Shopmaster'" class="lastMenuBtn">A<span class="org-text">I</span>-Shopmaster</button>
            <span v-else class="menu-item">{{ tab.name }}</span>
          </div>
          <div class="lang-icon">
            <img  :src="require('@/assets/icon/earth.png')" alt="">
          </div>
        </div>
        <img @click="showMenu" class="toggleIcon" src="@/assets/img/toggle.png">
      </div>
    </div>
    <el-drawer title="" :visible.sync="drawer" direction="ltr" :size="220">
      <div class="toggleMenu" v-for="(tab, index) in tabs" :key="index">
        <span class="menuItem" @click="gotoMenu(index)">{{ tab.name }}</span>
        <el-divider></el-divider>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      tabs: [
        { name: '首頁', path: '/home' },
        { name: 'AI-Shopmaster', path: '/solutions' },
        { name: '關於我們', path: '/about' },
        { name: '常見問題', path: '/questions' },
        { name: '聯絡我們', path: '/contact-us' },
        { name: '客戶登入', path: '/' },
      ],
      currentIndex: 0,
      hoverIndex: -1,
      drawer: false,
      lastMenuName:"",
      language: "zh"
    }
  },
  mounted() {  
    this.lastMenuName = this.getLastmenu();  
  },
  methods: {
    selectTab(index) {
      if (this.$route.path === this.tabs[index].path || this.tabs[index].path === '/') {
        this.scrollToTop();
        return;
      }
      this.currentIndex = index;
      this.hoverIndex = -1; // 重置 hoverIndex 
      this.$router.push(this.tabs[index].path); // 使用Vue Router导航到新的路由   
    },
    gotoMenu(index){
      this.drawer = false;
      if (this.$route.path === this.tabs[index].path) {
        return;
      }
      // this.scrollToTop();
      this.$router.push(this.tabs[index].path); // 使用Vue Router导航到新的路由   
    },
    hoverTab(index) {
      this.hoverIndex = index;
    },
    leaveTab() {
      this.hoverIndex = -1; // 当鼠标离开时重置 hoverIndex  
    },
    goHomePage() {
      if (this.$route.path === '/home') {
        this.scrollToTop();
      } else {
        this.$router.push('/home');
      }
    },
    scrollToTop() {
      this.$nextTick(() => {
        // 滚动到顶部（同步操作）  
        window.scrollTo({
          top: 0,
          // behavior: 'smooth'
        });
      })
    },
    showMenu() {
      this.drawer = true;
    },
    getLastmenu() {  
      const name = this.tabs.length > 0 ? this.tabs[this.tabs.length - 1].name : '';  
      return name;  
    }  
  }

}
</script>

<style scoped>
.header {
  /* height: 150px; */
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  /* 确保它在其他内容之上 */
  background-color: #fff;
  box-shadow: 0 3px 5px -1px rgba(164, 163, 163, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(158, 156, 156, 0.12);
  /* 下边框阴影 */
}


.logo {
  width: 210px;
  height: 100%;
  /* line-height: 150; */
  cursor: pointer;
}

.right {
  display: inline-block;
}


.tabs {
  display: flex;
  align-items: center;
}

.menu-item{
  font-weight: 700;
}

.tab {
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
  /* border: 1px solid #ddd;   */
  border-bottom: none;
  transition: background-color 0.3s ease;
  color: #000;
}

.tab.hover {
  color: var(--blue-color);
  font-weight: 500;
  /* color: #000; */
}

.tab.active {
  color: var(--blue-color);
  /* color: #000; */
  font-weight: 500;
  /* border-bottom: 1px solid var(--blue-color); */
}


.toggleIcon {
  width: 20px;
  height: 20px;
  display: none;
}

.toggleMenu{
  padding: 10px 20px;
}

.menuItem{
  font-weight: 700;
  cursor: pointer;
}

.el-divider--horizontal{
  margin: 10px 0;
}

.el-divider {
  background-color: #e3e4e6;
}

.lastMenuBtn{
  border: none;
  background-color: var(--blue-color);
  border-radius: 20px;
  color: #fff;
  padding: 10px 22px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* 添加过渡效果 */  
  font-size: 16px;
  /* letter-spacing: 1.1px; */
  font-family: 'Montserrat', sans-serif;
}

/* .lastMenuBtn:hover{
  letter-spacing: 1.2px;
  background-color: var(--blue-color);
} */

.loginMenu{
    display: flex;
    align-items: center;
  }

.lang-icon{
  margin-left: 30px;
  display: flex;
  align-items: center;
}

.lang-icon img{
  width: 20px;
  height: 20px;
}

.el-select /deep/ .el-input__inner {
  border: none !important; 
  outline: none !important; 
  box-shadow: none !important; 
}

.el-select /deep/ .el-input__inner:focus {
  border: none !important; /* 去除 focus 状态下的边框 */
  outline: none !important; /* 去除 focus 状态下的 outline */
  box-shadow: none !important; /* 去除 focus 状态下的阴影 */
}

.select{
  width: 100px;
}

@media (max-width: 970px) {
  .header {
    padding: 10px 25px 10px 5px;
  }

  img {
    /* width: 105px; */
    /* height: 24px; */
    width: 160px;
  }

  .tabs {
    display: none;
  }

  /* 折叠图标垂直居中 */
  .right {
    display: flex;
    align-items: center;
  }

  .toggleIcon {
    width: 23px;
    height: 23px;
    display: block;
  }

}
</style>
