import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        detail: null // 初始状态，detail 为空
    },
    getters: {
        // 定义一个 getter 来获取 detail 状态
        getCurrentDetail(state) {
            return state.detail;
        }
    },
    mutations: {
        // 定义一个 mutation 来更新 detail 状态
        setDetail(state, solution) {
          state.detail = solution;
        }
    },
    actions: {
        // 定义一个 action 来提交 mutation
        switchDetail({ commit }, solution) {
          commit('setDetail', solution);
        }
    }
});