<template>
  <div>
    <div class="border-title h1-black">
      方案介紹
    </div>
    <div class="grid-container">
      <div class="pic-container">
        <div v-for="(pic, index) in soutionDetail" :key="index" class="pic" @click="switchDetail(pic)">
          <img :src="require('@/assets/img/chatbot/' + pic.imgName + '.png')" alt="">
          <div class="ver-name">{{ pic.title }}</div>
        </div>
      </div>
      <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown" @before-enter="beforeEnter" @after-enter="afterEnter" @before-leave="beforeLeave" @after-leave="afterLeave">
        <div v-if="isNotNUll" class="detail-ver" ref="detailVer">
          <img :src="require('@/assets/img/chatbot/' + currentVersion.imgName + '.png')" alt="" class="detail-img animate__animated animate__zoomIn">
          <div class="text-column animate__animated animate__slideInUp">
            <div class="ver-name">{{ currentVersion.title }}</div>
            <p v-for="(text,index) in currentVersion.descrition" :key="index" class="mb-3">{{ text }}</p>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {soutionDetails} from "@/constants/index"


export default {
  name: 'soution-detail',
  data() {
    return {
      soutionDetail: soutionDetails,
      currentVersion: {}
    };
  },
  computed: {
    isNotNUll() {
      return Object.keys(this.currentVersion).length !== 0;
    },
    ...mapGetters([
      'getCurrentDetail'
    ])
  },
  watch: {
    getCurrentDetail(newVal) {
      if (newVal) {
        this.switchDetail(newVal);
      }
    },
  },
  methods: {
    switchDetail(val) {
      // 如果当前版本与点击的版本相同，则不进行操作
      // if (this.currentVersion === val) return;

      // 先隐藏当前详细信息，确保离开动画完成
      this.currentVersion = {};
      

      this.$nextTick(() => {
        // 更新当前版本并触发进入动画
        this.currentVersion = val;
        this.$store.dispatch('switchDetail', val);

        // 确保在DOM更新后并且动画完成后滚动到详情
        this.$nextTick(() => {
          if (this.isNotNUll) {
            this.scrollToDetail();
          }
        });
      });
    },
    scrollToDetail() {
      const detailVer = this.$refs.detailVer;
      if (detailVer) {
        this.$nextTick(() => {
          const offset = 150; 
          const targetPosition = detailVer.getBoundingClientRect().top + window.pageYOffset - offset;
          window.scrollTo({ top: targetPosition, behavior: 'smooth' });
        });
      }
    },
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = 'translateY(50px)';
    },
    afterEnter(el) {
      el.style.opacity = 1;
      el.style.transform = 'translateY(0)';
    },
    beforeLeave(el) {
      el.style.opacity = 1;
      el.style.transform = 'translateY(0)';
    },
    afterLeave(el) {
      el.style.opacity = 0;
      el.style.transform = 'translateY(-50px)';
    }
  },
};
</script>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* 3 列布局 */
  grid-template-rows: auto auto; /* 2 行布局，第一行自动高度，第二行自动高度 */
  gap: 40px; /* 图片之间的间距 */
  width: 100%; /* 占满整个容器 */
  align-items: start; /* 确保所有内容从顶部对齐 */
}

.border-title {
  border: 1px solid var(--main-color);
  padding: 10px 20px;
  border-radius: 10px;
  margin: 20px auto;
  text-align: center;
  width: 180px;
  grid-column: 1 / -1; /* 占据所有三列 */
}

.pic-container {
  display: flex;
  justify-content: space-around;
  grid-column: 1 / -1; /* 占据所有三列 */
}

.pic {
  text-align: center;
  flex-basis: 30%; /* 每个图片占据大约30%的宽度 */
  max-width: 300px; /* 最大宽度限制 */
}

.pic img, .detail-img {
  width: 300px;
  margin: 0 auto;
  height: auto; /* 保持图片比例 */
  object-fit: cover; /* 确保图片填充整个容器 */
  vertical-align: middle; /* 确保图片垂直居中 */
}

.detail-ver {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
}

.custom-classes-transition-enter-active,
.custom-classes-transition-leave-active {
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
}

.detail-ver img {
  grid-column: 1 / 2; /* 详情图片只占据左侧一列 */
}

.detail-ver .text-column {
  grid-column: 2 / -1; /* 文本部分占据右侧两列 */
}

.text-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* 确保文本从顶部开始 */
}

.detail-ver {
  display: grid;
  grid-template-columns: 1fr 2fr; /* 左边图片占一列，右边文本占两列 */
  gap: 40px; /* 图片和文本之间的间距 */
  grid-column: 1 / -1; /* 详情部分占据所有三列 */
  align-items: start; /* 确保图片和文本从顶部对齐 */
  /* opacity: 0; */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.detail-ver img {
  grid-column: 1 / 2; /* 详情图片只占据左侧一列 */
  transform: scale(0.8); /* 初始状态为缩小 */
  transition: transform 0.5s ease-in-out;
}

.detail-ver .text-column {
  grid-column: 2 / -1; /* 文本部分占据右侧两列 */
  /* transform: translateY(100%);  */
  transition: transform 0.5s ease-in-out;
}

.text-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* 确保文本从顶部开始 */
}

.ver-name {
  margin-top: 30px;
  padding: 10px 10px;
  background-color: var(--main-color);
  color: #fff;
  border-radius: 15px;
  font-size: 28px;
  font-weight: 700;
  width: 150px;
  margin: 30px auto;
  text-align: center;
}

/* 大屏幕适配 */
@media (min-width: 1200px) {
  .pic img, .detail-img {
    max-width: 400px; /* 在大屏幕上增加图片最大宽度 */
  }

  .pic-container {
    gap: 60px; /* 增加图片之间的间距 */
  }

  .text-column {
    max-width: 800px; /* 限制文本的最大宽度，避免过长 */
  }
}

@media (max-width: 1024px) {
  .grid-container {
    grid-template-columns: 1fr; /* 单列布局 */
    gap: 0px; /* 减小间距 */
  }

  .pic-container {
    flex-direction: column; /* 图片垂直排列 */
    align-items: center; /* 居中对齐 */
  }

  .pic {
    flex-basis: 100%; /* 每个图片占据整行 */
    max-width: 100%; /* 适应屏幕宽度 */
    margin-bottom: 20px; /* 增加图片之间的间距 */
  }
}

/* 小屏幕适配 */
@media (max-width: 768px) {





  .pic img, .detail-img {
    width: 100%; /* 图片占满容器 */
    max-width: 100%; /* 适应屏幕宽度 */
    height: auto; /* 保持图片比例 */
  }

  .ver-name {
    font-size: 20px; /* 减小标题字体大小 */
    /* width: 100%; */
    margin: 20px auto;
  }

  .text-column {
    max-width: 100%; /* 适应屏幕宽度 */
  }

  .detail-ver {
    grid-template-columns: 1fr; /* 单列布局 */
    gap: 20px; /* 减小间距 */
  }
}
</style>