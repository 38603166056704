export const soutionDetails =
    [
        {
            imgName: 'text',
            title: '文字版',
            descrition: [
                '創造出文字版24小时客服,即時以完滿的產品知識,解答消费者疑問，幫消費者快速節選合適產品，提供即時優惠資訊，並提供購物連結，促成即時購買。',
                '透過平时與店员聊天般的方式與AI-ShopMaster對話,他會詢問更多的细節諸如喜歡的顏色、品牌或風格,提供最適合的商品推薦。',
                `顾客可以開放地詢問,如:
聖誕節我該準備甚麽樣的禮物好呢?
我想找一些高含水量的隱形眼鏡?
我想要打造高科技感的書房,該買什麽样的家具呢?
AI-ShopMaster會根據和消費者的互動不斷學習,以提供更人性化的推薦服務。`
            ]
        },
        {
            imgName: 'cartoon',
            title: '卡通版',
            descrition: [
                '除了擁有卡通版的所有功能,真人版還可以使用人物(公司代言人或創辦人)創造出24小時視象語音客服。 親切專業的真人版 AI-ShopMaster,可以做到人物形象和不同情感的交互糸統,可以即時以表情和肢體動作出反應,做到彷以真人的活動,能夠更好地傳達情感和共鳴,提升客戶體驗。',
                `另外包括數據分析和報告,協助客戶了解網店上用戶的喜好。
市場調查是任何零售業的關鍵點。與目標受眾的聯繫有助了解客戶的需求、偏好和行為。
AI-ShopMaster可以成為市場調查的重要工具。透過分析客戶對話數據,AI-ShopMaster可以提供對買家行為的洞察力,推動客戶忠誠度。 
AI-ShopMaster還可以收集客戶反饋並進行調查,幫助商戶收集更多客戶偏好。企業領導人可以藉此打造更好的產品和營銷策略。
`
            ]
        },
        {
            imgName: 'digital',
            title: '真人版',
            descrition: [
                '除了擁有卡通版的所有功能,真人版還可以使用人物(公司代言人或創辦人)創造出24小時視象語音客服。 親切專業的真人版 AI-ShopMaster,可以做到人物形象和不同情感的交互糸統,可以即時以表情和肢體動作出反應,做到彷以真人的活動,能夠更好地傳達情感和共鳴,提升客戶體驗。',
                '由克隆人親自回答客戶問題,可以更專業的形象,向客戶展示公司的價值觀和使命。大大增加客戶對公司產品及服務的信心。真人版智能客服系統的應用潛力非常廣泛,可以應用於各種領域,為各種場景提供更加個性化和人性化的互動體驗。除了提高銷售額,建立品牌形象, 還可以將代言人的效用發至最大。'
            ]
        },
    ]