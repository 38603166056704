<template>
  <div>
    <div class="container">
      <div class="box">
        <div class="left">
          <div class="logo-container">
            <img src="@/assets/img/logo-white.png" alt="" class="logo">
          </div>
        </div>
        <div class="right">
          <div v-for="(item,idx) in itemList" :key="idx" class="itemList">
            <img :src="require('@/assets/icon/footer/'+item.icon)" alt="" class="icon">
            <span>{{ item.text }}</span>
          </div>
          <!-- <div class="bottom-text">
            <span v-for="(text) in textList" :key="text" class="rightText" @click="navigate(text)">{{ text }}</span>
          </div> -->
          <div class="ai-text">AI-ShopMaster</div>
          <div class="aism-container">
            <span v-for="(k,index) in soutions" :key="index" class="rightText" @click="navigate(k.title)">{{ k.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">© TOPZIO Limited Copyright, 2024.</div>
  </div>
</template>



<script>
import SolutionDetail from "@/view/components/solution-detail.vue";
import {soutionDetails} from "@/constants/index"

export default {
  name: 'Footer',
  components: {
    SolutionDetail
  },
  data() {
    return {
      itemList: [
        // {icon:"location.png",text:"香港九龍xx區xx道xx大廈xx樓xx室"},
        {icon:"email.png",text:"enquiry@aishopmaster.com"},
        {icon:"phone.png",text:"+852 2949 5355"},
        
      ],
      textList: ['隱私條款', '網站地圖', '常見問題'],
      soutions: soutionDetails
    }
  },
  methods:{
    navigate(text) {
      if(text === '常見問題' && this.$route.path!== '/questions'){
        this.$router.push('/questions');
      }
      else if (text === '網站地圖') {
        const mapUrl = '/navination';
        this.$route.path === mapUrl ?  this.scrollToTop() : this.$router.push('/navination')
      }
      else {
        const soutionObj = this.soutions.find(k => k.title === text);
        if (this.$route.path !== '/solutions') {
          this.$router.push('/solutions').then(() => {
            setTimeout(() => {
              this.dispatchSwitchDetail(soutionObj);  
            }, 300);
          })
        }
        else {
          this.dispatchSwitchDetail({});
          this.dispatchSwitchDetail(soutionObj);
        }
      }
    },
    dispatchSwitchDetail(solutionObj) {
      // 调用 Vuex action
      this.$store.dispatch('switchDetail', solutionObj);
    },
    scrollToTop() {
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          // behavior: 'smooth'
        });
      })
    },
  }

}
</script>

<style scoped>
.container {
  background-color: var(--blue-color);
  padding: 30px 8%;
  color: #fff;
  font-weight: 700;
}

.box{
  display: flex;
}

.left {
  line-height: 30px;
  flex: 3;
  padding-right: 20px;
  border-right: 1px solid #fff;
}

.logo-container{
  /* height: 150px; */
  display: flex;
  align-items: center;
}

.aism-container{
  height: 20%;
  display: flex;
  align-items: center;
}


.logo{
  width: 80%;
}

.right {
  flex: 3;
  padding-left: 8%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.itemList{
  display: flex;
  align-items: center;
}

.icon{
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.rightText{
  margin-right: 10%;
  cursor: pointer;
  font-weight: 700;
}

.copyright{
  background-color: #ffd900;
  color: var(--blue-color);
  font-weight: 700;
  padding: 5px 8%;
}

.ai-text{
  font-size: 20px;
  font-weight: 700;
}


@media (max-width: 768px) {
  .container{
    padding: 20px 40px;
    display: flex;
    justify-content: center;
  }

  .box {
    display: block;
    
  }

  .left{
    border-right: none;
    padding-right: 0;
  }

  .right {
    margin-top: 15px;
    padding-left: 0;
  }

  .rightText{
    margin-right: 50px;
  }

  .itemList{
    margin-bottom: 10px;
  }

  .aism-container{
    margin-top: 15px;
  }
}
</style>
