import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '*',
      redirect: '/'
    }, {
      path: '/',
      // name: 'PageView',
      component: resolve => require(['@/view/PageView'], resolve),
      children: [
        {
          path: '/',
          redirect: '/home'
        }, {
          path: '/home',
          name: 'home',
          component: resolve => require(['@/view/HomePage/index'], resolve),
          meta: {
            title: '首頁'
          }
        },
        {
          path: '/about',
          name: 'about',
          component: resolve => require(['@/view/About'], resolve),
          meta: {
            title: '關於我們'
          },
        },
        {
          path: '/solutions',
          name: 'AiShopMaster',
          component: resolve => require(['@/view/AiShopMaster'], resolve),
          meta: {
            title: 'AiShopMaster'
          },
        },
        {
          path: '/questions',
          name: 'FAQ',
          component: resolve => require(['@/view/FAQ'], resolve),
          meta: {
            title: '常見問題'
          },
        },
        {
          path: '/contact-us',
          name: 'ContactUs',
          component: resolve => require(['@/view/ContactUs'], resolve),
          meta: {
            title: '聯絡我們'
          }
        },
        {
          path: '/navination',
          name: 'Navination',
          component: resolve => require(['@/view/WebsiteMap'], resolve),
          meta: {
            title: '網站地圖'
          }
        },
      ]
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
